import React from 'react'
import Helmet from 'react-helmet'

const Person = ({
  type
}) => {

  const dentist =
    <script type="application/ld+json">
      {
        `{
          "@context": "https://schema.org",
          "@type": "Dentist",
          "name": "Dentistas - Realize Sorrisos",
          "image": "https://realizesorrisos-uploads.s3.amazonaws.com/logo-realize-sorrisos-200.webp",
          "@id": "",
          "url": "https://realizesorrisos.com/",
          "telephone": "(61) 3522-1850",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "St. M QNM 17 4º Andar",
            "addressLocality": "Ceilândia, Brasília",
            "postalCode": "72215-172",
            "addressCountry": "BR"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": -15.8159687,
            "longitude": -48.1011238
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday"
            ],
            "opens": "08:00",
            "closes": "18:00"
          }
        }`
      }
    </script>

  const person =
    <script type="application/ld+json">
      {
        `{
          "@context": "https://schema.org",
          "@type": "Person",
          "name": "Denise Fontenelle",
          "jobTitle": "Dentista especialista em Ortodontia e Ortopedia Funcional do Maxilares",
          "url": "https://realizesorrisos.com/author/denise-fontenelle/",
          "image": "https://realizesorrisos-uploads.s3.amazonaws.com/dentista-em-ceilandia.webp",
          "description": "Dra. Denise Fontenelle é especialista há mais de 12 anos e se formou na Universidade de Fortaleza – UniFor. Ela possui título de especialista em Ortodontia e Ortopedia Funcional do Maxilares, além de aperfeiçoamento em cirurgia oral, mini-implantes e aparelhos auto-ligados.",
          "alumniOf": "Universidade de Fortaleza - UniFor",
          "worksFor": {
            "@type": "Organization",
            "name": "Realize Sorrisos"
          }
        }`
      }
    </script>

  if (type === 'dentist') {
    return (
      <Helmet>
        {dentist}
      </Helmet>
    )
  }
  if (type === 'person') {
    return (
      <Helmet>
        {person}
      </Helmet>
    )
  }
}

export default Person






